import { Box, styled } from "@mui/material"
import { colorPalette } from "../../styling/theme"

export const ErrorContainer = styled(Box)`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  border-radius: 24px;
  background-color: ${colorPalette.deepPurple};
  color: ${colorPalette.white};
`

import { Box, Grid, Typography } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { useParams } from "react-router-dom"
import CatAllObservables from "../../components/CatAllObservables/CatAllObservables"
import CollectionDatasets from "../../components/CollectionDatasets/CollectionDatasets"
import PageLoader from "../../components/PageLoader.tsx/PageLoader"
import ResourceHeader from "../../components/ResourceHeader/ResourceHeader"
import { useGetCollectionMetadataByNameIdQuery } from "../../utils/redux/queries/catalog.queries"
import { ResourceTypeOdp } from "../../utils/sdk/utils/entities/sdk.resource.types"
import { renderErrors } from "../Catalog/catalog.helpers"
import { S } from "./CatalogCollection.style"
import MetadataTable from "./components/MetadataTable"

const CatalogCollection = () => {
  const { id: nameId } = useParams()
  const { data, isFetching, error } = useGetCollectionMetadataByNameIdQuery(nameId ?? skipToken)

  if (isFetching) return <PageLoader />
  if (error) return renderErrors(error)
  if (!data) return <PageLoader />

  const collectionDatasetsHeader = <S.SectionHeader>Datasets</S.SectionHeader>

  return (
    <Grid container direction="column" sx={{ pl: 4 }}>
      <Grid item container sx={{ mb: 3, mt: 2 }}>
        <Grid item>
          <ResourceHeader name={data.name} type="Data Collection" />
        </Grid>
      </Grid>
      <Grid item container spacing={{ md: 6 }} sx={{ mb: 3 }}>
        <Grid item container md={7} direction="column" spacing={3}>
          <Grid item>
            <S.SectionHeader>Overview</S.SectionHeader>
            <Typography>{data.description}</Typography>
          </Grid>
          <Grid item>
            <S.SectionHeader>Metadata</S.SectionHeader>
            <MetadataTable {...data} />
          </Grid>
          <CollectionDatasets collectionNameId={data.nameId} headerComponent={collectionDatasetsHeader} />
          {!!data.tags?.length && (
            <Grid item container sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <S.SectionHeader>Tags</S.SectionHeader>
              <Box sx={{ ml: { md: 1 } }}>
                {data.tags.map(el => (
                  <S.Tag key={"tag_" + el}>{el}</S.Tag>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item container md={5} spacing={3} direction="column">
          <Grid item sx={{ mt: 2 }}>
            {nameId && <CatAllObservables nameId={nameId} type={ResourceTypeOdp.Collection} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(CatalogCollection)

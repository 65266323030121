import React from "react"
import { externalPaths } from "../../../components/navigation.helpers"
import { colorPalette } from "../../../styling/theme"
import DashbordItem from "./components/DashbordItem"
import WidgetButton from "./components/WidgetButton"
import WidgetContent from "./components/WidgetContent"
import WidgetHeader from "./components/WidgetHeader"

const NewToOdpWidget = () => {
  const onClick = () => {
    window.open(externalPaths.RegisterForm)
  }

  return (
    <DashbordItem sx={{ backgroundColor: colorPalette.luminousCoralO20 }} elevation={0}>
      <WidgetHeader title="New user?" color={colorPalette.luminousCoral} />
      <WidgetContent description="Welcome to the Ocean Data Platform! Click below to create an account" />
      <WidgetButton onClick={onClick} backgroundColor={colorPalette.luminousCoral} textColor={colorPalette.white}>
        Create account
      </WidgetButton>
    </DashbordItem>
  )
}

export default React.memo(NewToOdpWidget)

import { Card, CardContent, List, Typography } from "@mui/material"
import React from "react"
import type { AccessRequest as AccessRequestType } from "../../../../utils/api/AccessManagement/resourceAccess.types"
import AccessReqestStatus from "./components/AccessReqestStatus"
import PermissionRequest from "./components/PermissionRequest"

const AccessRequest = (p: AccessRequestType) => {
  return (
    <Card elevation={0} variant="outlined">
      <CardContent>
        <AccessReqestStatus status={p.status} />
        <Typography sx={{ mt: 2 }}>You have requested access to: </Typography>
        <List dense>
          {p.permissions.workspaces?.map(el => (
            <PermissionRequest key={el} premission={el} />
          ))}
          {p.permissions.resource_groups?.map(el => (
            <PermissionRequest key={el} premission={el} />
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default React.memo(AccessRequest)

import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { CatalogMode } from "../../components/CollectionDatasetSwitch"
import { useLoggedInUserInfo } from "../../utils/auth/userHooks"
import { useCheckMyData401 } from "../../utils/hooks/resourceHooks"
import { resetMyDataFiltersRA } from "../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../utils/redux/store"
import AwaitsAccessPage from "../ErrorPage/AwaitsAccessPage"
import AddDataCollectionModal from "./components/AddDataCollectionModal"
import MyCollections from "./components/MyCollections"
import MyDataHeader from "./components/MyDataHeader/MyDataHeader"
import MyDatasets from "./components/MyDatasets/MyDatasets"

const MyData = () => {
  const [mode, setMode] = useState<CatalogMode>(CatalogMode.Collection)
  const { isNonWhitelisted } = useLoggedInUserInfo()
  const dispatch = useAppDispatch()

  const { has401Error, isFulfilled } = useCheckMyData401()

  const showHeader = !has401Error && isFulfilled
  const showPendingAccessRequest = isNonWhitelisted

  const isCollectionMode = mode === CatalogMode.Collection
  const title = mode === CatalogMode.Collection ? "My Data Collections" : "My Datasets"

  useEffect(() => {
    dispatch(resetMyDataFiltersRA())
  }, [dispatch])

  if (showPendingAccessRequest) return <AwaitsAccessPage />

  return (
    <>
      <Box
        position={"fixed"}
        color={"red"}
        zIndex={-1}
        sx={{
          opacity: 0.2,
          left: 0,
          "&img": {
            width: "100vw",
          },
        }}
        width={"100vw"}>
        <Box
          src={isCollectionMode ? "/bgSvg/bg_spiral_green.svg" : "/bgSvg/bg_spiral_purple.svg"}
          alt="Ocean Data Platform"
          component={"img"}
          sx={{ width: { md: "100vw" } }}
        />
      </Box>
      {showHeader && <MyDataHeader header={title} currentMode={mode} setMode={setMode} />}
      {isCollectionMode && <MyCollections />}
      {!isCollectionMode && <MyDatasets />}
      <AddDataCollectionModal />
    </>
  )
}

export default MyData

import type { ResourceGroup, ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import type { Modify } from "../../utils"

export enum AccessRoleId {
  Viewer = "3",
  Editor = "2",
  Admin = "1",
}

// For v1 of access controll only user will be supported
export enum UserType {
  user = "user",
  serviceAccount = "serviceAccount",
  userGroup = "userGroup",
}

export type ResourceAccess = {
  object: RelationshipObject
  role: AccessRoleId
  subject: ResourceUser
}

export type CreateResourceAccessDto = {
  object: RelationshipObject
  role: AccessRoleId
  subject: RelationshipSubject
}

export type RemoveResourceAccessDto = {
  object: {
    id: string
    kind: ResourceKind
  }
  role: AccessRoleId
  subject: RelationshipSubjectId
}

export type ResourceUser = {
  email: string
  id: string
  type: UserType
}

type RelationshipSubjectId = {
  id: string
}

type RelationshipSubject = { email?: string; id?: string }

type RelationshipObject = {
  id: string
  kind: ResourceKind
}

export type UserIdentifier = { userEmail: string; userId?: string } | { userId: string; userEmail?: string }

// ### Waitlist types ###
export type WaitlistUser = {
  email: string
  id: string
  fullname: string
  org?: string
  country?: string
  role?: string
  social?: {
    linkedin?: string
  }
}

export enum AccessRequestStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export enum WorkspacePermissions {
  Basic = "8fa722ae-ff94-482a-b908-5df3aee7b233",
  SmallNotebook = "cd8a0494-784e-4499-ac51-21aeb88bcb95",
}

export type AccessRequestPermissions = {
  workspaces: WorkspacePermissions[]
  resource_groups: ResourceGroup[]
}

export type PermissionApi = {
  permission_id: string
  user: WaitlistUser
  status: AccessRequestStatus
  requested: string
  updated: string
  permissions: AccessRequestPermissions
}

export type AccessRequest = Modify<PermissionApi, { requested: Date; updated: Date }>

import CircleIcon from "@mui/icons-material/Circle"
import { ListItem, ListItemIcon, ListItemText, type SxProps } from "@mui/material"
import React from "react"

type Props = {
  text: string
  sx?: SxProps
}

const BulletListItem = (p: Props) => {
  return (
    <ListItem sx={p.sx}>
      <ListItemIcon sx={{ minWidth: "24px" }}>
        <CircleIcon sx={{ fontSize: 7 }} />
      </ListItemIcon>
      <ListItemText>{p.text}</ListItemText>
    </ListItem>
  )
}

export default React.memo(BulletListItem)

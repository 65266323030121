import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

export const applicationSegments = {
  Dashboard: "/",
  Catalog: "/catalog",
  "My Data": "/data_collections",
}

export type PageNames = keyof typeof applicationSegments

export const externalSegments = {
  Documentation: "http://docs.hubocean.earth/",
  Community: "https://ocean-data-community.slack.com/",
}

export type ExternalPageNames = keyof typeof externalSegments

const getRegisterLink = (envName: string) => {
  switch (envName) {
    case "production":
      return "https://oceandataplatform.b2clogin.com/oceandataplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_REGISTER_OPEN&client_id=473167a6-0748-4cfa-8853-571c6b8a80fe&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.hubocean.earth/registered%2F&scope=openid&response_type=id_token&prompt=login"
    case "test":
      return "https://oceandataplatform.b2clogin.com/oceandataplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_REGISTER_OPEN&client_id=473167a6-0748-4cfa-8853-571c6b8a80fe&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fproud-forest-0804c7a03-test.westeurope.2.azurestaticapps.net/registered%2F&scope=openid&response_type=id_token&prompt=login"
    case "stage":
      return "https://oceandataplatform.b2clogin.com/oceandataplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_REGISTER_OPEN&client_id=473167a6-0748-4cfa-8853-571c6b8a80fe&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fproud-forest-0804c7a03-stage.westeurope.2.azurestaticapps.net/registered%2F&scope=openid&response_type=id_token&prompt=login"
    case "local":
      return "https://oceandataplatform.b2clogin.com/oceandataplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_REGISTER_OPEN&client_id=473167a6-0748-4cfa-8853-571c6b8a80fe&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost:3000/registered%2F&scope=openid&response_type=id_token&prompt=login"
    default:
      return "https://oceandataplatform.b2clogin.com/oceandataplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_REGISTER_OPEN&client_id=473167a6-0748-4cfa-8853-571c6b8a80fe&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.oceandata.earth/registered%2F&scope=openid&response_type=id_token&prompt=login"
  }
}

export const externalPaths = {
  ...externalSegments,
  Connector: "https://workspace.hubocean.earth",
  TabularUploadNotebook:
    "https://github.com/C4IROcean/OceanDataConnector/blob/master/workflow-examples/tabular-data-upload-ILIAD-example/example_publish_iliad.ipynb",
  RegisterForm: getRegisterLink(process.env.REACT_APP_ENV ?? "production"),
  RequestAccessForm: "https://issues.hubocean.earth/form/709ec86c-4b3b-479f-9231-f9e8e5c91167",
}

export const paths = {
  ...applicationSegments,
  Datasets: "/datasets",
  CatalogCollection: "/catalog/collection/:id",
  CatalogDataset: "/catalog/dataset/:id",
  NotFound: "/404",
  Unauthorized: "/401",
  Projects: "/apps",
  InternalAdmin: "/admin",
  UserRegistered: "/registered",
  MyAccess: "/my_access",
}

export const useOdpNavigate = () => {
  const nav = useNavigate()
  const odpInternalNav = useCallback(
    (pageName: PageNames) => {
      const path = applicationSegments[pageName] || "/"
      nav(path)
    },
    [nav]
  )

  const odpExternalNav = useCallback((pageName: ExternalPageNames) => {
    const path = externalSegments[pageName]
    window.open(path)
  }, [])

  return { odpInternalNav, odpExternalNav }
}

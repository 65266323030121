import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import PendingIcon from "@mui/icons-material/Pending"
import { Box, Link, Typography } from "@mui/material"
import React from "react"
import { externalPaths } from "../../../../../components/navigation.helpers"
import { colorPalette } from "../../../../../styling/theme"
import { AccessRequestStatus } from "../../../../../utils/api/AccessManagement/resourceAccess.types"

type Props = {
  status: AccessRequestStatus
}

const getStatusIcon = (status: AccessRequestStatus) => {
  switch (status) {
    case AccessRequestStatus.Approved:
      return <CheckCircleOutlineIcon sx={{ color: colorPalette.surfieGreen }} />
    case AccessRequestStatus.Pending:
      return <PendingIcon sx={{ color: colorPalette.luminousYellow }} />
    case AccessRequestStatus.Rejected:
      return <CancelIcon sx={{ color: colorPalette.luminousCoral }} />
    default:
      return <PendingIcon />
  }
}

const getStatusSubtitle = (status: AccessRequestStatus) => {
  switch (status) {
    case AccessRequestStatus.Approved:
      return (
        <Typography variant="subtitle1" sx={{}}>
          Ready to start? Access your services via the dashboard.
        </Typography>
      )
    case AccessRequestStatus.Pending:
      return (
        <Typography variant="subtitle1" sx={{}}>
          Still waiting? Requests typically take 3–5 business days to process. If you haven’t received an update within
          this timeframe, please reach out to support through{" "}
          <Link href={externalPaths.RequestAccessForm} target="_blank" rel="noopener noreferrer">
            this form.
          </Link>
        </Typography>
      )
    case AccessRequestStatus.Rejected:
      return (
        <Typography variant="subtitle1" sx={{}}>
          Need help understanding why your request was rejected? Reach out to support through{" "}
          <Link href={externalPaths.RequestAccessForm} target="_blank" rel="noopener noreferrer">
            this form.
          </Link>
        </Typography>
      )
    default:
      return "Access request is pending"
  }
}

const AccessReqestStatus = (p: Props) => {
  const icon = getStatusIcon(p.status)

  return (
    <Box>
      <Box display="flex" alignItems={"center"} marginBottom={1}>
        <Typography sx={{ mr: 0.5, fontWeight: "bold" }}>Status: {p.status}</Typography>
        {icon}
      </Box>
      <Typography variant="subtitle1" sx={{}}>
        {getStatusSubtitle(p.status)}
      </Typography>
    </Box>
  )
}

export default React.memo(AccessReqestStatus)

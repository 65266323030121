import { List, Stack } from "@mui/material"
import React from "react"
import CustomAlert from "../../../components/CustomAlert"
import PageLoader from "../../../components/PageLoader.tsx/PageLoader"
import type { AccessRequestStatus } from "../../../utils/api/AccessManagement/resourceAccess.types"
import { useGetWaitlistUsersQuery } from "../../../utils/redux/queries/access.queries"
import UserAccessRequest from "./UserAccessRequest"

const WaitlistUsers = (p: { status: AccessRequestStatus }) => {
  const { data: waitlistUsers, isFetching, error } = useGetWaitlistUsersQuery(p.status)

  return (
    <List>
      {error && <CustomAlert error={error} />}
      {isFetching && <PageLoader />}
      <Stack spacing={1}>
        {!isFetching && waitlistUsers?.map(el => <UserAccessRequest key={el.permission_id} {...el} />)}
      </Stack>
    </List>
  )
}

export default React.memo(WaitlistUsers)

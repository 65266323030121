import { useIsAuthenticated } from "@azure/msal-react"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { paths } from "../../components/navigation.helpers"
import { isWhitelisted } from "../../utils/auth/auth.helpers"
import NewUserWelcome from "./components/NewUserWelcome"
import WhitelistedUserWelcome from "./components/WhitelistedUserWelcome"

const WelcomeRegisteredUserPage = () => {
  const authenticated = useIsAuthenticated()

  const nav = useNavigate()

  const isUserWhitelisted = isWhitelisted()

  useEffect(() => {
    if (authenticated) {
      nav(paths.Dashboard)
    }
  }, [authenticated, nav])

  if (isUserWhitelisted) return <WhitelistedUserWelcome />
  return <NewUserWelcome />
}

export default React.memo(WelcomeRegisteredUserPage)

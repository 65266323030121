import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { colorPalette } from "../../../styling/theme"

type Props = {
  date: Date
  text: string
}

const AccessRequestDate = (p: Props) => {
  return (
    <Box display="flex">
      <Typography sx={{ fontSize: "0.9rem", mr: 1 }}>{p.text + dayjs(p.date).format("DD/MM/YYYY")}</Typography>
      <Typography sx={{ fontSize: "0.9rem", color: colorPalette.deepPurpleO60 }}>
        {dayjs(p.date).format("HH:mm [GMT]Z")}
      </Typography>
    </Box>
  )
}

export default React.memo(AccessRequestDate)

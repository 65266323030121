import { Container, Tab, Tabs, Typography } from "@mui/material"
import React from "react"
import CustomAlert from "../../components/CustomAlert"
import { AccessRequestStatus } from "../../utils/api/AccessManagement/resourceAccess.types"
import { useGetMyUserStatusQuery } from "../../utils/redux/queries/access.queries"
import WaitlistUsers from "./components/WaitlistUsers"

const AdminPanelPage = () => {
  const { data: isUserAdmin, isFetching, error } = useGetMyUserStatusQuery()

  const [accessStatus, setAccessStatus] = React.useState(AccessRequestStatus.Pending)

  const handleChange = (_: React.SyntheticEvent, newValue: AccessRequestStatus) => {
    setAccessStatus(newValue)
  }

  if (error) return <CustomAlert error={error} />
  if (isFetching) return <>Loading...</>
  if (!isUserAdmin) return <>You are not an admin</>

  return (
    <Container maxWidth="md">
      <Typography variant="h5" sx={{ mb: 1 }}>
        User waitlist
      </Typography>
      <Tabs value={accessStatus} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Pending" value={AccessRequestStatus.Pending} />
        <Tab label="Rejected" value={AccessRequestStatus.Rejected} />
        <Tab label="Approved" value={AccessRequestStatus.Approved} />
      </Tabs>
      <WaitlistUsers status={accessStatus} />
    </Container>
  )
}

export default React.memo(AdminPanelPage)

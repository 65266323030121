import React from "react"
import WelcomeUserMessage from "./WelcomeUserMessage"

const NewUserWelcome = () => {
  return (
    <WelcomeUserMessage introText="Thank you for your interest in Ocean Data Platform! You are almost in, we just  need to add you as a user before you can log on, and will inform you by email when this is done. Once you have been added, you will have access to the following features: " />
  )
}

export default React.memo(NewUserWelcome)

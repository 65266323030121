import React from "react"
import WelcomeUserMessage from "./WelcomeUserMessage"

const WhitelistedUserWelcome = () => {
  return (
    <WelcomeUserMessage
      introText="We are excited to inform you are now a user in the Ocean Data Platform. You can now log in and explore the features we offer to help you make the most of your data. As a free-tier user, you have access to the following features:"
      outroText="We look forward to supporting your journey on the Ocean Data Platform. Feel free to reach out if you have any questions or need assistance. "
    />
  )
}

export default React.memo(WhitelistedUserWelcome)

import { type SerializedError } from "@reduxjs/toolkit"
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import CustomAlert from "../../components/CustomAlert"
import { paths } from "../../components/navigation.helpers"
import Page401 from "../ErrorPage/Page401"
import Page404 from "../ErrorPage/Page404"

export const useCatalogSearch = () => {
  const navigate = useNavigate()

  const searchFunction = (searchString: string) => {
    navigate({
      pathname: "/catalog/search",
      search: `?q=${searchString}`,
    })
  }

  return { searchFunction }
}

export const catalogCollectionPage = (collectionNameId: string) =>
  `${paths.CatalogCollection.replace(":id", collectionNameId)}`

export const catalogDatasetPage = (productId: string) => `${paths.CatalogDataset.replace(":id", productId)}`

/**
 * Observable defines some information about the object and references this object using notation
 * more complex than just its' quallified name. For example for dataset with qualified name 'test-dataset' reference name is
 * catalog.hubocean.io/test-dataset
 *
 * @returns string - qualified name
 * @param referenceId string that references resource for which observable is definied
 */
export const getNameIdFromReferenceId = (referenceId: string) => {
  return referenceId.split("catalog.hubocean.io/").pop()
}

export const useErrorRedirects = (error: FetchBaseQueryError | SerializedError | undefined) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!error || !("status" in error)) return
    switch (error.status) {
      case 404:
        return navigate(paths.NotFound)
      case 401:
        return navigate(paths.Unauthorized)
      default:
        break
    }
  }, [error, navigate])
}

export const renderErrors = (error: FetchBaseQueryError | SerializedError) => {
  if (!("status" in error)) return <CustomAlert error={error} />
  switch (error.status) {
    case 404:
      return <Page404 />
    case 401:
      return <Page401 />
    default:
      return <CustomAlert error={error} />
  }
}

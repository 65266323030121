import React from "react"
import BulletListItem from "../../../../../components/BulletListItem/BulletListItem"
import { WorkspacePermissions } from "../../../../../utils/api/AccessManagement/resourceAccess.types"
import { ResourceGroup } from "../../../../../utils/sdk/utils/entities/sdk.resource.types"

type Props = {
  premission: WorkspacePermissions | ResourceGroup
}

const getPremissionName = (premission: WorkspacePermissions | ResourceGroup) => {
  switch (premission) {
    case WorkspacePermissions.Basic:
      return "Workspaces Access"
    case WorkspacePermissions.SmallNotebook:
      return "Small Notebook Environment"
    case ResourceGroup.catalog:
      return "Data Catalog"
    case ResourceGroup.data:
      return "Data"
    default:
      return "- Unrecognized permission -"
  }
}

const PermissionRequest = (p: Props) => {
  return <BulletListItem text={getPremissionName(p.premission)} />
}

export default React.memo(PermissionRequest)

import { Alert, Link } from "@mui/material"
import React from "react"
import { externalPaths } from "../../../../../../../../../components/navigation.helpers"

const UserNotFoundError = () => {
  return (
    <Alert severity="error" sx={{ mb: 2 }}>
      <span style={{ pointerEvents: "none" }}>
        The user is not a member for the Ocean Data Platform yet. They can create an account by using{" "}
      </span>
      <Link
        href={externalPaths.RegisterForm}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          position: "relative",
          zIndex: 1,
          display: "inline-block", // Ensures padding applies correctly
          padding: "0 2px", // Adds slight padding to improve clickability
        }}>
        this form.
      </Link>
    </Alert>
  )
}

export default React.memo(UserNotFoundError)

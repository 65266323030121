import React from "react"
import { useNavigate } from "react-router-dom"
import { paths } from "../../components/navigation.helpers"
import { colorPalette } from "../../styling/theme"
import WidgetButton from "../Dashboard/Widgets/components/WidgetButton"
import ErrorPage from "./ErrorPage"

const AwaitsAccessPage = () => {
  const nav = useNavigate()

  const clickGoToCheckAccessPage = () => {
    nav(paths.MyAccess)
  }

  const clickCheckAccessBtn = (
    <WidgetButton
      onClick={clickGoToCheckAccessPage}
      textColor={colorPalette.deepPurple}
      backgroundColor={colorPalette.luminousGreen}
      sx={{ alignSelf: "center" }}>
      Check request status
    </WidgetButton>
  )

  return (
    <ErrorPage
      text="Thanks for registering! Your account is currently pending approval. You'll receive an email once your request is approved."
      button={clickCheckAccessBtn}
    />
  )
}

export default React.memo(AwaitsAccessPage)

import { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import type {
  AccessRequest,
  AccessRoleId,
  CreateResourceAccessDto,
  PermissionApi,
  RemoveResourceAccessDto,
  UserIdentifier,
} from "./resourceAccess.types"

export const createUserDto = ({
  userEmail,
  roleId,
  resourceId,
  userId,
}: {
  roleId: AccessRoleId
  resourceId: string
} & UserIdentifier): CreateResourceAccessDto => {
  return {
    object: {
      id: resourceId,
      kind: ResourceKind.collection,
    },
    role: roleId,

    subject: {
      email: userEmail,
      id: userId,
    },
  }
}

export const createDeleteUserDto = ({
  userAzureId,
  roleId,
  resourceId,
}: {
  userAzureId: string
  roleId: AccessRoleId
  resourceId: string
}): RemoveResourceAccessDto => {
  return {
    object: {
      id: resourceId,
      kind: ResourceKind.collection,
    },
    role: roleId,

    subject: {
      id: userAzureId,
    },
  }
}

export const mapToPermission = (apiPermission: PermissionApi): AccessRequest => {
  return {
    ...apiPermission,
    requested: new Date(apiPermission.requested),
    updated: new Date(apiPermission.updated),
  }
}

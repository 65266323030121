import { Container, Stack, Typography } from "@mui/material"
import React from "react"
import CustomAlert from "../../components/CustomAlert"
import PageLoader from "../../components/PageLoader.tsx/PageLoader"
import { useGetUserAccessStatusQuery } from "../../utils/redux/queries/access.queries"
import AccessRequest from "./components/AccessRequest/AccessRequest"

const MyAccessPage = () => {
  const { data, error, isFetching } = useGetUserAccessStatusQuery()

  return (
    <Container>
      <Typography variant="h5" sx={{ mb: 2 }}>
        My Access
      </Typography>
      {isFetching && <PageLoader />}
      {error && <CustomAlert error={error} />}
      {!data?.length && !isFetching && <Typography>{"You don't have any access requests."}</Typography>}
      <Stack spacing={2} sx={{ mb: 2 }}>
        {!!data?.length && data.map(el => <AccessRequest key={"users_req_" + el.permission_id} {...el} />)}
      </Stack>
    </Container>
  )
}

export default React.memo(MyAccessPage)

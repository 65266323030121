import EditIcon from "@mui/icons-material/Edit"
import { Box, Grid } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DetailsHeader from "../../components/DetailsHeader/DetailsHeader"
import EditResourceModal from "../../components/EditResourceModal/EditResourceModal"
import PageLoader from "../../components/PageLoader.tsx/PageLoader"
import { paths } from "../../components/navigation.helpers"
import { colorPalette } from "../../styling/theme"
import { useIsUserOwner, useResourceAccess } from "../../utils/auth/userHooks"
import { createMyCollectionPath } from "../../utils/frontendTypes/datasets.types"
import { useGetDatasetByNameQuery } from "../../utils/redux/queries/dataset.queries"
import { renderErrors } from "../Catalog/catalog.helpers"
import MyDatasetContent from "./components/MyDatasetContent/MyDatasetContent"
import MyDatasetMetadata from "./components/MyDatasetMetadata/MyDatasetMetadata"
import MyDatasetTools from "./components/MyDatasetTools/MyDatasetTools"

const DatasetDetails = () => {
  const navigate = useNavigate()

  const { datasetID } = useParams()

  const [editModalOpen, setEditModalOpen] = useState(false)

  const closeModalFn = useCallback(() => {
    setEditModalOpen(false)
  }, [])

  const { data: dataset, isLoading, isFetching, error } = useGetDatasetByNameQuery(datasetID ?? skipToken)

  const { hasWriteAccess: hasWriteAccessByRelationship } = useResourceAccess(dataset?.uuid)

  const isOwner = useIsUserOwner(dataset?.ownerId)

  const hasWriteAccess = hasWriteAccessByRelationship || isOwner

  useEffect(() => {
    if (!datasetID) {
      navigate(paths.Datasets)
    }
  }, [navigate, datasetID, error])

  if (error) return renderErrors(error)
  if (!dataset || isLoading || isFetching) return <PageLoader color={colorPalette.deepPurpleO50} />

  const clickEdit = () => {
    setEditModalOpen(true)
  }

  const collectionLink = dataset.collectionId ? createMyCollectionPath(dataset.collectionId) : undefined

  return (
    <Box sx={{ pl: 4, pt: 2 }}>
      <DetailsHeader
        clickEdit={hasWriteAccess ? clickEdit : undefined}
        icon={<EditIcon />}
        text={dataset.name}
        type="Dataset"
        description={dataset.description}
        tags={dataset.tags}
      />
      <Grid container sx={{ marginTop: "50px", minHeight: "500px", pb: 2 }}>
        {/* Left column */}
        <Grid item sm={12} md={6} lg={4} sx={{ borderRight: { md: "1px solid black" }, pr: { md: 4 }, py: 2 }}>
          <MyDatasetContent dataset={dataset} />
        </Grid>

        {/* Middle column */}
        <Grid item sm={12} md={6} lg={4} sx={{ px: { md: 4 }, py: 2 }} container spacing={5} direction={"column"}>
          <MyDatasetMetadata dataset={dataset} />
        </Grid>

        {/* Right column */}
        <Grid item sm={12} md={6} lg={4} sx={{ borderLeft: { lg: "1px solid black" }, pl: { lg: 4 }, py: 2 }}>
          <MyDatasetTools dataset={dataset} />
        </Grid>
      </Grid>
      <EditResourceModal
        resourceUuid={dataset.uuid}
        open={editModalOpen}
        closeFn={closeModalFn}
        type="Dataset"
        deleteRedirectUrl={collectionLink}
        mainInfo={{
          name: dataset.name,
          description: dataset.description,
          tags: dataset.tags ?? [],
        }}
      />
    </Box>
  )
}

export default DatasetDetails

import type {
  ResourceGroup,
  ResourceKind,
  ResourceMetadataDtoGet,
  ResourceStatus,
  ResourceVersionType,
} from "../sdk/utils/entities/sdk.resource.types"
import { type Modify } from "../utils"

// Tentative, deduced from example token
export type decodedJWT = {
  exp: number // expiration timestamp
  nbf: number // not valid before timestamp
  ver: string
  iss: string // link to the issuer
  sub: string // subject, azure object id
  aud: string
  acr: string // authentication context class reference
  nonce: string
  iat: number // issued at timestamp
  auth_time: number // authentication timestamp
  email: string
  given_name: string
  family_name: string
  jsonBody: string // JSON body of the token
  policyBindingResponse: string // JSON response from the policy binding
  userGroupsResponse: string // JSON response from the user groups
  tid: string // tenant id
}

type userGroupsResponse =
  | {
      status: "OK"
      message: string
    }
  | {
      status: "Warning"
      message: "User groups skipped."
    }

type policyBindingResponseSuccess = {
  kind: ResourceKind.policyBinding
  version: ResourceVersionType
  metadata: ResourceMetadataDtoGet
  status: ResourceStatus
  spec: {
    policy_selector: object
    consumer: string
    valid_from: null
    valid_to: null
    extra_affiliate: null
    resource_groups: ResourceGroup[]
    critical: false
  }
}

type policyBindingResponseWarning = {
  status: "Warning"
  message: "Policy binding skipped."
}

type policyBindingResponse = policyBindingResponseSuccess | policyBindingResponseWarning

export type parsedJWT = Modify<
  decodedJWT,
  { jsonBody: object; policyBindingResponse: policyBindingResponse; userGroupsResponse: userGroupsResponse }
>

export const decodeJWT = (idToken: string) => {
  try {
    const base64Url = idToken.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = atob(base64)
    return JSON.parse(jsonPayload)
  } catch (error) {
    console.error("Failed to decode ID Token:", error)
    return null
  }
}

export const parseJWT = (token: string): parsedJWT | null => {
  const decodedToken = decodeJWT(token)

  if (!decodedToken) {
    console.error("Failed to decode token")
    return null // Handle the case where decoding fails
  }

  const safeParse = (key: string): any => {
    try {
      return JSON.parse(decodedToken[key])
    } catch (error) {
      console.warn(`Failed to parse property ${key}:`, error)
      return undefined // Return undefined for unparseable properties
    }
  }

  return {
    ...decodedToken,
    jsonBody: safeParse("jsonBody"),
    policyBindingResponse: safeParse("policyBindingResponse"),
    userGroupsResponse: safeParse("userGroupsResponse"),
  }
}

export const isWhitelistedFromToken = (token: string): boolean => {
  const parsedToken = parseJWT(token)
  if (!parsedToken) return false
  return "kind" in parsedToken.policyBindingResponse
}

export const isWhitelisted = () => {
  const hash = window.location.hash.substring(1)
  const params = new URLSearchParams(hash) // Parse the hash fragment as query params

  const idToken = params.get("id_token")
  if (!idToken) return false

  return isWhitelistedFromToken(idToken)
}

import PersonIcon from "@mui/icons-material/Person"
import { Avatar, Box, Link, ListItem, ListItemAvatar, ListItemText, Snackbar, Typography } from "@mui/material"
import React from "react"
import CustomAlert from "../../../components/CustomAlert"
import { Loader } from "../../../components/Loader/Loader"
import { StyledButton } from "../../../components/StyledButton"
import { colorPalette } from "../../../styling/theme"
import { AccessRequestStatus, type AccessRequest } from "../../../utils/api/AccessManagement/resourceAccess.types"
import { useSetUserAccessStatusMutation } from "../../../utils/redux/queries/access.queries"
import AccessRequestDate from "./AccessRequestDate"

const UserAccessRequest = (p: AccessRequest) => {
  const [setAccessRequestStatus, { error, isLoading, isSuccess }] = useSetUserAccessStatusMutation()

  const clickApprove = async (prevouslyRejected?: boolean) => {
    await setAccessRequestStatus({
      permissionId: p.permission_id,
      status: AccessRequestStatus.Approved,
      permissions: p.permissions,
      prevouslyRejected,
    })
  }

  const clickReject = async () => {
    await setAccessRequestStatus({
      permissionId: p.permission_id,
      status: AccessRequestStatus.Rejected,
      permissions: p.permissions,
    })
  }

  const isPending = p.status === AccessRequestStatus.Pending
  const isApproved = p.status === AccessRequestStatus.Approved
  const isRejected = p.status === AccessRequestStatus.Rejected
  const toApprove = isPending

  const color = isPending
    ? colorPalette.deepPurpleO30
    : isApproved
    ? colorPalette.luminousGreen
    : colorPalette.luminousCoral

  return (
    <>
      <Snackbar open={isSuccess} autoHideDuration={6000} message="Successfully updated request status." />
      {error && <CustomAlert error={error} />}
      <ListItem sx={{ border: `1px solid ${colorPalette.deepPurpleO10}`, borderRadius: 2 }} secondaryAction={<></>}>
        <ListItemAvatar>
          {!isLoading && (
            <Avatar sx={{ bgcolor: color }}>
              <PersonIcon />
            </Avatar>
          )}
          {isLoading && <Loader size={30} />}
        </ListItemAvatar>
        <ListItemText>
          <Box display="flex" justifyContent={"space-between"}>
            <Box>
              <Box display={"flex"} sx={{ mb: 1 }} flexWrap={"wrap"}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>{`${p.user.fullname}`}</Typography>
                <Typography sx={{ color: colorPalette.deepPurpleO60 }}>{`(${p.user.email})`}</Typography>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Box>
                  {p.user.role && (
                    <Typography sx={{ fontSize: "0.9rem", color: colorPalette.deepPurpleO60 }}>
                      {"Role: " + p.user.role}
                    </Typography>
                  )}
                </Box>
                <Box>
                  {p.user.country && (
                    <Typography sx={{ fontSize: "0.9rem", color: colorPalette.deepPurpleO60 }}>
                      {"Country: " + p.user.country}
                    </Typography>
                  )}
                </Box>
                <Box>
                  {p.user.org && (
                    <Typography sx={{ fontSize: "0.9rem", color: colorPalette.deepPurpleO60 }}>
                      {"Organisation: " + p.user.org}
                    </Typography>
                  )}
                </Box>
                <Box>
                  {p.user.social?.linkedin && (
                    <Link
                      href={p.user.social?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontSize: "0.9rem", color: colorPalette.deepPurpleO60 }}>
                      {p.user.social?.linkedin}
                    </Link>
                  )}
                </Box>
              </Box>
              <AccessRequestDate date={p.requested} text={"Submitted on: "} />
              {isApproved && <AccessRequestDate date={p.updated} text={"Approved on: "} />}
              {isRejected && <AccessRequestDate date={p.updated} text={"Rejected on: "} />}
            </Box>

            <Box sx={{ my: "auto" }}>
              {toApprove && (
                <StyledButton
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 1, my: 1 }}
                  onClick={async () => await clickApprove(isRejected)}>
                  Approve
                </StyledButton>
              )}
              {isPending && (
                <StyledButton variant="outlined" color="primary" onClick={clickReject}>
                  Reject
                </StyledButton>
              )}
            </Box>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  )
}

export default React.memo(UserAccessRequest)

import { Container, List, Typography } from "@mui/material"
import React from "react"
import BulletListItem from "../../../components/BulletListItem/BulletListItem"

type Props = {
  introText: string
  outroText?: string
}

const WelcomeUserMessage = (p: Props) => {
  return (
    <Container maxWidth={"md"} sx={{}}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {"Welcome to Ocean Data Platform"}
      </Typography>

      <Typography>{p.introText}</Typography>
      <List sx={{ my: 1.5 }}>
        <BulletListItem
          sx={{ py: 0 }}
          text="Explore Public Data Catalog: Browse through a wide range of publicly available datasets."
        />
        <BulletListItem
          sx={{ py: 0 }}
          text="Create Your Own Datasets: Build and organize your own datasets and collaborate seamlessly with your colleagues."
        />
        <BulletListItem
          sx={{ py: 0 }}
          text="Leverage Jupyter Notebook Workspaces: Dive into your data, analyze it, and create insights with our built-in Jupyter notebook tools. "
        />
      </List>

      {p.outroText && <Typography sx={{ mb: 2 }}>{p.outroText}</Typography>}
      <Typography>{"Best regards, "}</Typography>
      <Typography>{"The Ocean Data Platform Team"}</Typography>
    </Container>
  )
}

export default React.memo(WelcomeUserMessage)
